import {AFD} from 'devegram-javascript-util'
const baseUrl = '/xapi/Storage/File';
const moduleAjaxService = 'SS'
const api = {
    namespaced: true,
    state: {
        list: {},
        dropDownList: [],
        pageChunkSize: 24
    },
    actions: {
        async dashboardInfo (context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dashboardInfo'
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        /* CRUD APIs */
        async create(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/store',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    // context.state.dropDownList.push({value: r.data.id, label: r.data.id})
                    // context.state.list.push({value: r.data.id, label: r.data.id})
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async update(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/update/' + params.id,
                data: AFD(params.data),
                noLoadingScreen: true
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async remove(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/remove/' + params.id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async multipleDelete(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/multipleDelete',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async info(context, id) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/info/' + id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async consume(context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/consume/' + params.id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async list(context, params) {
            let page = (params.page)? params.page : 1
            let pageChunk = '&page-chunk=' + context.state.pageChunkSize
            let search = (params.hasOwnProperty('search'))? "&search=" + encodeURIComponent(params.search) : ''

            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/list?page=' + page + pageChunk + search,
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {

                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async getDropDownList (context, params = {}) {


            let query = ''
            if (params.hasOwnProperty('valueKey')) {
                query = '?value_key=' + params.valueKey
            }
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dropDownList' + query,
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, { root: true }).then(r => {
                    context.state.dropDownList = r.data
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },

        /* Files APIs */
         async updateFile(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/updateFile/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async updateImage(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/updateImage/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async updateGallery(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/galleryImage/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async removeGalleryImage(context, params) {
            let api = {
                verb: 'delete',
                service: moduleAjaxService,
                url: baseUrl + '/galleryImage/' + params.id + '/' + params.parentId + '/' + params.fileId,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
    },
    mutations: {
        RESET_CACHE (state, params) {
            state.list = {}
            state.dropDownList = []
        }
    }
};

export default api
